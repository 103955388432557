html, body, .App {
  height: 100%;
  background-color: #282c34;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.App-logo {
  Display: flex;
Height: 100vh;
Width: 100vw; 
Align-Items: center;
Justify-Content: centre;
}


@import url('https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900&display=swap');