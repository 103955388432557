.Container {
    height: 100%;
    background-color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .red {
    background-color: red;
  }
  
  .input {
    background-color: 'transparent';
    color: '#7A7A7A';
    font-size: 12px;
    border-radius: 30;
    border-width: 0;
    height: 40;
    
    }
    .input:focus { 
        outline: none !important;
        border-color: white;
        box-shadow: 0 0 10px transparent;
    }

    .end{
      position: absolute;
      right: 0;
      align-items: flex-end;
    }