@import url(https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900&display=swap);
body {
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, .App {
  height: 100%;
  background-color: #282c34;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.App-logo {
  Display: flex;
Height: 100vh;
Width: 100vw; 
Align-Items: center;
Justify-Content: centre;
}
.Container {
    height: 100%;
    background-color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .red {
    background-color: red;
  }
  
  .input {
    background-color: 'transparent';
    color: '#7A7A7A';
    font-size: 12px;
    border-radius: 30;
    border-width: 0;
    height: 40;
    
    }
    .input:focus { 
        outline: none !important;
        border-color: white;
        box-shadow: 0 0 10px transparent;
    }

    .end{
      position: absolute;
      right: 0;
      align-items: flex-end;
    }
